<template>
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5">
            {{headerText}}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <BaseDatePickerWithText 
                label="Run Date mm/dd/yyyy"
                v-model="selectedWolfsRun.RunDate" 
                :rules="[v => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                label="Item Count" 
                v-model="selectedWolfsRun.ItemCount"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field 
                label="Total Value" 
                v-model="selectedWolfsRun.TotalValue"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox 
                label="Is Disbursed" 
                v-model="selectedWolfsRun.IsDisbursed"
                :rules="[v => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox 
                label="Is Canceled" 
                v-model="selectedWolfsRun.IsCanceled"
                :rules="[v => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveWolfsRunDetails" :disabled="saving" color="primary">Save</v-btn>
          <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import WolfsRun from '@classes/WolfsRun'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedWolfsRun: new WolfsRun(),
    headerText: 'WolfsRun',
  }),
  created() {},
  computed: {
  ...get('wolfsRun', ['saving']),
  inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('wolfsRun', [
    'saveWolfsRun'
    ]),
    async validate() {
      await this.$refs.form.validate()
    },
    editWolfsRun(entry) {
      this.selectedWolfsRun = new WolfsRun(entry)
      this.headerText = 'Edit Wolfs Run'
    },
    addWolfsRun() {
      this.headerText = 'Insert Wolfs Run'
      this.selectedWolfsRun = new WolfsRun()
    },
    async saveWolfsRunDetails() {
      await this.validate()
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          try {
            let res = await this.saveWolfsRun(this.selectedWolfsRun.removeRelated())
            this.$emit('refresh')
            this.$emit('itemAdded', res.data)
            this.selectedWolfsRun = new WolfsRun()
            this.$refs.form.resetValidation()
          } catch (err) {
          }
          this.$emit('update:dialog', false)
        }
    },
    cancelEntry() {
      this.selectedWolfsRun = new WolfsRun()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    }
  },
  } 
  
</script>
<style>

</style>