<template>
  <BasePanelWithHeader
    collapsable
    headerText="Applications Available For Payment"
  >
    <BaseSimpleDataTable
      :selected.sync="selected"
      item-key="ApplicationId"
      :headers="filteredHeaders"
      :items="filteredVwApplicationsAvailableForPayments"
      :loading="loading"
      search
      show-select
      checkbox-color="primary"
    >
      <template v-slot:prependParams>
        <v-row class="mt-3">
          <v-col cols="12" md="3">
            <LuFundingSource
              label="Funding Source"
              v-model="fundingSourceId"
            ></LuFundingSource>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn
              color="primary"
              @click="generateWolfsRun"
              :disabled="!fundingSourceId > 0 || selected.length == 0"
              >Generate Wolfs Run For Selected</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="ma-2 mb-5"
            v-if="
              selectedFundingAppropriation &&
              selectedFundingAppropriation.Id &&
              selectedFundingAppropriation.Id > 0 &&
              selectedFundingAppropriation.FundingSourceId == fundingSourceId
            "
          >
            <v-simple-table dense
              ><tbody>
                <tr>
                  <td>Original Amount</td
                  ><td>{{
                    selectedFundingAppropriation.OriginalAmount | formatMoney
                  }}</td>
                </tr>
                <tr>
                  <td>Disbursed Amount</td
                  ><td>{{
                    selectedFundingAppropriation.DisbursedAmount | formatMoney
                  }}</td>
                </tr>
                <tr>
                  <td>Committed Amount</td
                  ><td>{{
                    selectedFundingAppropriation.CommittedAmount | formatMoney
                  }}</td>
                </tr>
                <tr>
                  <td>Available Amount</td
                  ><td>{{
                    selectedFundingAppropriation.AvailableAmount | formatMoney
                  }}</td>
                </tr>
              </tbody></v-simple-table
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:appendParams> </template>
      <template v-slot:item.ApplicationId="{ item }">
        <BaseTooltipButton
          v-if="item.ApplicationId"
          small
          iconColor="primary"
          icon="mdi-file-document"
          @click="
            $router.push({
              name: 'staffForm',
              params: { appId: item.ApplicationId },
            })
          "
          >Go To Application
        </BaseTooltipButton></template
      >
      <template v-slot:item.LastName="{ item }">
        {{ item.FirstName }} {{ item.MiddleInitial }}
        {{ item.LastName }}</template
      >
      <template v-slot:item.CurrentStatusDate="{ item }">{{
        item.CurrentStatusDate | formatDateTime
      }}</template>
      <template v-slot:item.RefundAmount="{ item }">{{
        item.RefundAmount | formatMoney
      }}</template>
    </BaseSimpleDataTable>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuFundingSource from '@components/select/LuFundingSource/LuFundingSource-single.vue'
export default {
  components: {
    LuFundingSource,
  },
  data: () => ({
    selected: [],
    fundingSourceId: null,
    expanded: [],
    selectedFundingAppropriation: {},
  }),
  created() {
    this.loadVwApplicationsAvailableForPayments()
    this.loadCurrentLuPeriod()
  },
  computed: {
    ...get('vwApplicationsAvailableForPayment', [
      'vwApplicationsAvailableForPayments',
      'loading',
    ]),
    ...get('luPeriod', ['currentLuPeriod']),
    filteredVwApplicationsAvailableForPayments() {
      var list = this.vwApplicationsAvailableForPayments.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'ApplicationId',
          visible: true,
        },
        {
          text: 'Application Number',
          align: 'center',
          sortable: true,
          value: 'ApplicationNumber',
          visible: true,
        },
        {
          text: 'Current Status Id',
          align: 'center',
          sortable: true,
          value: 'CurrentStatusId',
          visible: false,
        },
        {
          text: 'Approval Date',
          align: 'center',
          sortable: true,
          value: 'CurrentStatusDate',
          visible: true,
        },
        {
          text: 'Budget Fiscal Year',
          align: 'center',
          sortable: true,
          value: 'BudgetFiscalYear',
          visible: false,
        },
        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: false,
        },
        {
          text: 'Middle Initial',
          align: 'center',
          sortable: true,
          value: 'MiddleInitial',
          visible: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Mailing Address 1',
          align: 'center',
          sortable: true,
          value: 'MailingAddress1',
          visible: true,
        },
        {
          text: 'Mailing Address 2',
          align: 'center',
          sortable: true,
          value: 'MailingAddress2',
          visible: true,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City',
          visible: true,
        },
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'State',
          visible: true,
        },
        {
          text: 'Zip',
          align: 'center',
          sortable: true,
          value: 'Zip',
          visible: true,
        },
        {
          text: 'Refund Amount',
          align: 'center',
          sortable: true,
          value: 'RefundAmount',
          visible: true,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('vwApplicationsAvailableForPayment', [
      'loadVwApplicationsAvailableForPayments',
    ]),
    ...call('wolfsRun', ['generateRunFromSelected', 'loadWolfsRuns']),
    ...call('fundingAppropriation', [
      'getFundingAppropriationByFundingSourceAndPeriod',
    ]),
    ...call('luPeriod', ['loadCurrentLuPeriod']),
    async generateWolfsRun() {
      let ids = this.selected.map((i) => i.ApplicationId)
      if (ids.length > 0) {
        let payload = { ids, fundingSourceId: this.fundingSourceId }
      try {
        let res = await this.generateRunFromSelected(payload)
        this.loadVwApplicationsAvailableForPayments()
        this.loadWolfsRuns()
      } catch (err) {}
      }
    },
  },
  watch: {
    fundingSourceId: {
      async handler(nval, oval) {
        if (nval && nval > 0) {
          //get funding appropriation for fundingSourceId and currentperiod
          let res = await this.getFundingAppropriationByFundingSourceAndPeriod({
            fundingSourceId: nval,
            periodId: this.currentLuPeriod.Id,
          })
          this.selectedFundingAppropriation = res.data
        }
      },
    },
  },
}
</script>
<style></style>
