<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <WolfsRunForm 
      ref="wolfsRunForm" 
      :dialog.sync="dialog" 
      @refresh="$emit('refresh')" 
      @itemAdded="$emit('itemAdded', $event)">
    </WolfsRunForm>
  </v-dialog>
</template>
<script>
  import WolfsRunForm from '@components/form/add-edit/WolfsRunForm'
export default {
  components: {
    WolfsRunForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    editWolfsRun(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.wolfsRunForm.editWolfsRun(entry)
      })
    },
    addWolfsRun() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.wolfsRunForm.addWolfsRun()
      })
    },
  },
  } 
  
</script>
<style>

</style>