<template
  ><v-container>
    <ApplicationsAvailableForPayment></ApplicationsAvailableForPayment>
    <WolfsRun></WolfsRun>
  </v-container>
</template>
<script>
import ApplicationsAvailableForPayment from '@components/admin/VwApplicationsAvailableForPaymentGrid'
import WolfsRun from '@components/admin/WolfsRunGrid'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: { ApplicationsAvailableForPayment, WolfsRun },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
