var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BasePanelWithHeader',{attrs:{"collapsable":"","headerText":"Wolfs Runs"}},[_c('BaseSimpleDataTable',{attrs:{"item-key":"Id","headers":_vm.filteredHeaders,"items":_vm.filteredWolfsRuns,"loading":_vm.loading,"search":"","expanded":_vm.expanded,"single-expand":"","show-expand":"","custom-filter":_vm.searchFilter},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"prependParams",fn:function(){return [_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-switch',{attrs:{"label":"Show Canceled"},model:{value:(_vm.showCanceled),callback:function ($$v) {_vm.showCanceled=$$v},expression:"showCanceled"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-switch',{attrs:{"label":"Show Disbursed"},model:{value:(_vm.showDisbursed),callback:function ($$v) {_vm.showDisbursed=$$v},expression:"showDisbursed"}})],1)]},proxy:true},{key:"appendParams",fn:function(){return undefined},proxy:true},{key:"item.edit",fn:function({ item }){return [(!item.IsCanceled && !item.IsDisbursed)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-check-decagram"},on:{"click":function($event){return _vm.$refs.wolfsDisburseConfirm.confirmItem(item)}}},[_vm._v("Mark As Disbursed ")]):_vm._e(),(!item.IsCanceled)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-download"},on:{"click":function($event){return _vm.downloadXml(item)}}},[_vm._v("Download Wolfs File ")]):_vm._e(),(!item.IsCanceled)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-format-text-rotation-vertical"},on:{"click":function($event){return _vm.downloadText(item)}}},[_vm._v("Download Wolfs Text File ")]):_vm._e(),_c('excelExport',{attrs:{"small":"","icon":"mdi-table-arrow-down","iconColor":"primary","data":item.WolfsItems,"exportFields":{
          'Doc No': { width: 20, field: 'DocumentNumber' },
          'Vendor Name': 'LegalName',
          'Fund Code': 'FundCode',
          'Appr Unit': 'AppropriationUnit',
          'Org Code': 'AgencyCode',
          'Object Code': 'ObjectCode',
          'Sub Object': 'SubObjectCode',
          'Line Amount': {
            field: 'LineAmount',
            format: '$#,##0.00',
            sum: true,
          },
        },"summaryData":[
          { Name: 'Wolfs Certification Report' },
          {
            Name: `For Period:`,
            Data: _vm.dayjs(item.RunDate).format('MM/DD/YYYY'),
          },
          { Name: `Row Count:`, Data: item.ItemCount },
          { Name: `Total Value:`, Data: `$${item.TotalValue}` },
        ],"worksheet":"Sheet1","name":`Wolfs${item.Id}Export.xlsx`}},[_vm._v("Export Grid Details")])]}},{key:"item.delete",fn:function({ item }){return [(!item.IsCanceled && !item.IsDisbursed)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-close-circle"},on:{"click":function($event){return _vm.$refs.wolfsRunCancel.deleteConfirm(item)}}},[_vm._v("Cancel Run ")]):_vm._e()]}},{key:"item.RunDate",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.RunDate)))]}},{key:"item.CreatedBy",fn:function({ item }){return [(item.CreatedBy)?_c('div',[_vm._v(" "+_vm._s(item.CreatedBy.FirstName)+" "+_vm._s(item.CreatedBy.LastName)+" ")]):_vm._e()]}},{key:"item.UpdatedBy",fn:function({ item }){return [(item.UpdatedBy)?_c('div',[_vm._v(" "+_vm._s(item.UpdatedBy.FirstName)+" "+_vm._s(item.UpdatedBy.LastName)+" ")]):_vm._e()]}},{key:"item.UpdatedDate",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.UpdatedDate)))]}},{key:"item.TotalValue",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatMoney")(item.TotalValue)))]}},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.filteredItemHeaders,"items":item.WolfsItems},scopedSlots:_vm._u([{key:"item.ApplicationId",fn:function({ item }){return [(item.ApplicationId)?_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-file-document"},on:{"click":function($event){return _vm.$router.push({
                  name: 'staffForm',
                  params: { appId: item.ApplicationId },
                })}}},[_vm._v("Go To Application ")]):_vm._e()]}},{key:"item.LineAmount",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatMoney")(item.LineAmount)))]}},{key:"item.CreatedDate",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.UpdatedDate)))]}},{key:"item.UpdatedDate",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.UpdatedDate)))]}},{key:"item.CreatedBy",fn:function({ item }){return [(item.CreatedBy)?_c('div',[_vm._v(" "+_vm._s(item.CreatedBy.FirstName)+" "+_vm._s(item.CreatedBy.LastName)+" ")]):_vm._e()]}},{key:"item.UpdatedBy",fn:function({ item }){return [(item.UpdatedBy)?_c('div',[_vm._v(" "+_vm._s(item.UpdatedBy.FirstName)+" "+_vm._s(item.UpdatedBy.LastName)+" ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"ma-4 ml-auto",attrs:{"sm":"auto"}},[_vm._v("Line Count : "+_vm._s(item.WolfsItems.length))]),_c('v-col',{staticClass:"ma-4",attrs:{"sm":"auto"}},[_vm._v("Total Line Amounts: "+_vm._s(_vm._f("formatMoney")(item.WolfsItems.reduce((prev, line) => { return prev + line.LineAmount }, 0))))])],1)]},proxy:true}],null,true)})],1)]}}])}),_c('BaseConfirm',{ref:"wolfsDisburseConfirm",attrs:{"confirm":_vm.disburseRun,"closeText":"Close","confirmText":"Disburse Run"},on:{"refresh":_vm.loadWolfsRuns}},[_c('div',[_vm._v(" This action will disburse the funds in this run and mark all applications associated with this run as refunded. Proceed? ")])]),_c('BaseDeleteConfirm',{ref:"wolfsRunCancel",attrs:{"delete":_vm.cancelRun,"closeText":"Close","confirmText":"Cancel Run"},on:{"refresh":_vm.loadWolfsRuns}},[_vm._v(" Are you sure you want to cancel this wolfs run? ")]),_c('WolfsRunDialogForm',{ref:"wolfsRunForm",on:{"refresh":_vm.loadWolfsRuns}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }