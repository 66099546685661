<template>
  <BasePanelWithHeader collapsable headerText="Wolfs Runs">
    <BaseSimpleDataTable
      item-key="Id"
      :headers="filteredHeaders"
      :items="filteredWolfsRuns"
      :loading="loading"
      search
      :expanded.sync="expanded"
      single-expand
      show-expand
      :custom-filter="searchFilter"
    >
      <template v-slot:prependParams>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showCanceled" label="Show Canceled"></v-switch>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-switch v-model="showDisbursed" label="Show Disbursed"></v-switch>
        </v-col>
      </template>
      <template v-slot:appendParams> </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          v-if="!item.IsCanceled && !item.IsDisbursed"
          small
          @click="$refs.wolfsDisburseConfirm.confirmItem(item)"
          iconColor="primary"
          icon="mdi-check-decagram"
          >Mark As Disbursed
        </BaseTooltipButton>

        <BaseTooltipButton
          v-if="!item.IsCanceled"
          small
          @click="downloadXml(item)"
          iconColor="primary"
          icon="mdi-download"
          >Download Wolfs File
        </BaseTooltipButton>
        <BaseTooltipButton
          v-if="!item.IsCanceled"
          small
          @click="downloadText(item)"
          iconColor="primary"
          icon="mdi-format-text-rotation-vertical"
          >Download Wolfs Text File
        </BaseTooltipButton>
        <excelExport
          small
          icon="mdi-table-arrow-down"
          iconColor="primary"
          :data="item.WolfsItems"
          :exportFields="{
            'Doc No': { width: 20, field: 'DocumentNumber' },
            'Vendor Name': 'LegalName',
            'Fund Code': 'FundCode',
            'Appr Unit': 'AppropriationUnit',
            'Org Code': 'AgencyCode',
            'Object Code': 'ObjectCode',
            'Sub Object': 'SubObjectCode',
            'Line Amount': {
              field: 'LineAmount',
              format: '$#,##0.00',
              sum: true,
            },
          }"
          :summaryData="[
            { Name: 'Wolfs Certification Report' },
            {
              Name: `For Period:`,
              Data: dayjs(item.RunDate).format('MM/DD/YYYY'),
            },
            { Name: `Row Count:`, Data: item.ItemCount },
            { Name: `Total Value:`, Data: `$${item.TotalValue}` },
          ]"
          worksheet="Sheet1"
          :name="`Wolfs${item.Id}Export.xlsx`"
          >Export Grid Details</excelExport
        >
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          v-if="!item.IsCanceled && !item.IsDisbursed"
          small
          @click="$refs.wolfsRunCancel.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-close-circle"
          >Cancel Run
        </BaseTooltipButton>
      </template>
      <template v-slot:item.RunDate="{ item }">{{
        item.RunDate | formatDateTime
      }}</template>
      <template v-slot:item.CreatedBy="{ item }"
        ><div v-if="item.CreatedBy">
          {{ item.CreatedBy.FirstName }} {{ item.CreatedBy.LastName }}
        </div>
      </template>
      <template v-slot:item.UpdatedBy="{ item }">
        <div v-if="item.UpdatedBy">
          {{ item.UpdatedBy.FirstName }} {{ item.UpdatedBy.LastName }}
        </div>
      </template>
      <template v-slot:item.UpdatedDate="{ item }">{{
        item.UpdatedDate | formatDateTime
      }}</template>
      <template v-slot:item.TotalValue="{ item }">{{
        item.TotalValue | formatMoney
      }}</template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-2">
          <v-data-table :headers="filteredItemHeaders" :items="item.WolfsItems">
            <template v-slot:item.ApplicationId="{ item }">
              <BaseTooltipButton
                v-if="item.ApplicationId"
                small
                iconColor="primary"
                icon="mdi-file-document"
                @click="
                  $router.push({
                    name: 'staffForm',
                    params: { appId: item.ApplicationId },
                  })
                "
                >Go To Application
              </BaseTooltipButton></template
            >
            <template v-slot:item.LineAmount="{ item }">{{
              item.LineAmount | formatMoney
            }}</template>
            <template v-slot:item.CreatedDate="{ item }">{{
              item.UpdatedDate | formatDateTime
            }}</template>
            <template v-slot:item.UpdatedDate="{ item }">{{
              item.UpdatedDate | formatDateTime
            }}</template>
            <template v-slot:item.CreatedBy="{ item }"
              ><div v-if="item.CreatedBy">
                {{ item.CreatedBy.FirstName }} {{ item.CreatedBy.LastName }}
              </div>
            </template>
            <template v-slot:item.UpdatedBy="{ item }">
              <div v-if="item.UpdatedBy">
                {{ item.UpdatedBy.FirstName }} {{ item.UpdatedBy.LastName }}
              </div>
            </template>
            <template v-slot:footer>
              <v-row>
                <v-col sm="auto" class="ma-4 ml-auto"
                  >Line Count : {{ item.WolfsItems.length }}</v-col
                >
                <v-col sm="auto" class="ma-4"
                  >Total Line Amounts:
                  {{
                    item.WolfsItems.reduce((prev, line) => {
                      return prev + line.LineAmount
                    }, 0) | formatMoney
                  }}</v-col
                >
              </v-row>
            </template>
          </v-data-table>
        </td>
      </template>
    </BaseSimpleDataTable>
    <BaseConfirm
      ref="wolfsDisburseConfirm"
      :confirm="disburseRun"
      @refresh="loadWolfsRuns"
      closeText="Close"
      confirmText="Disburse Run"
    >
      <div>
        This action will disburse the funds in this run and mark all
        applications associated with this run as refunded. Proceed?
      </div>
    </BaseConfirm>
    <BaseDeleteConfirm
      ref="wolfsRunCancel"
      :delete="cancelRun"
      @refresh="loadWolfsRuns"
      closeText="Close"
      confirmText="Cancel Run"
    >
      Are you sure you want to cancel this wolfs run?
    </BaseDeleteConfirm>
    <WolfsRunDialogForm ref="wolfsRunForm" @refresh="loadWolfsRuns">
    </WolfsRunDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import dayjs from 'dayjs'
import WolfsRunDialogForm from '@components/form/add-edit/WolfsRunDialog'
import WolfsRun from '@classes/WolfsRun'
import excelExport from '@components/excelExport'
export default {
  components: {
    WolfsRunDialogForm,
    excelExport,
  },
  data: () => ({
    showDisbursed: false,
    showCanceled: false,
    expanded: [],
    dayjs,
  }),
  created() {
    this.loadWolfsRuns()
  },
  computed: {
    ...get('wolfsRun', ['wolfsRuns', 'loading']),
    filteredWolfsRuns() {
      var list = this.wolfsRuns
        .filter((i) => true)
        .map((i) => {
          return {
            ...i,
            itemJson: JSON.stringify(i.WolfsItems),
          }
        })
      if (!this.showCanceled) {
        list = list.filter((i) => !i.IsCanceled)
      }
      if (!this.showDisbursed) {
        list = list.filter((i) => !i.IsDisbursed)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Run Date',
          align: 'center',
          sortable: true,
          value: 'RunDate',
          visible: true,
        },
        {
          text: 'Item Count',
          align: 'center',
          sortable: true,
          value: 'ItemCount',
          visible: true,
        },
        {
          text: 'Total Value',
          align: 'center',
          sortable: true,
          value: 'TotalValue',
          visible: true,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedById',
          visible: false,
        },
        {
          text: 'Is Disbursed',
          align: 'center',
          sortable: true,
          value: 'IsDisbursed',
          visible: this.showDisbursed,
        },
        {
          text: 'Is Canceled',
          align: 'center',
          sortable: true,
          value: 'IsCanceled',
          visible: this.showCanceled,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          visible: true,
        },
        {
          text: 'Updated By',
          align: 'center',
          sortable: true,
          value: 'UpdatedBy',
          visible: this.showCanceled || this.showDisbursed,
        },
        {
          text: 'Updated By',
          align: 'center',
          sortable: true,
          value: 'UpdatedById',
          visible: false,
        },
        {
          text: 'Updated Date',
          align: 'center',
          sortable: true,
          value: 'UpdatedDate',
          visible: this.showCanceled || this.showDisbursed,
        },
        {
          text: '',
          align: 'center',
          value: 'itemJson',
          filterable: true,
          visible: false,
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    itemHeaders() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: false,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Wolfs Run Id',
          align: 'center',
          sortable: true,
          value: 'WolfsRunId',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'ApplicationId',
          visible: true,
        },
        {
          text: 'Funding Source Id',
          align: 'center',
          sortable: true,
          value: 'FundingSourceId',
          visible: false,
        },
        {
          text: 'Budget Fiscal Year',
          align: 'center',
          sortable: true,
          value: 'BudgetFiscalYear',
          visible: true,
        },
        {
          text: 'Document Number',
          align: 'center',
          sortable: true,
          value: 'DocumentNumber',
          visible: true,
        },
        {
          text: 'Legal Name',
          align: 'center',
          sortable: true,
          value: 'LegalName',
          visible: true,
        },
        {
          text: 'Address 1',
          align: 'center',
          sortable: true,
          value: 'Address1',
          visible: true,
        },
        {
          text: 'Address 2',
          align: 'center',
          sortable: true,
          value: 'Address2',
          visible: true,
        },
        {
          text: 'City',
          align: 'center',
          sortable: true,
          value: 'City',
          visible: true,
        },
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'State',
          visible: true,
        },
        {
          text: 'Zip',
          align: 'center',
          sortable: true,
          value: 'Zip',
          visible: true,
        },
        {
          text: 'Agency',
          align: 'center',
          sortable: true,
          value: 'AgencyCode',
          visible: true,
        },
        {
          text: 'Fund',
          align: 'center',
          sortable: true,
          value: 'FundCode',
          visible: true,
        },
        {
          text: 'Object',
          align: 'center',
          sortable: true,
          value: 'ObjectCode',
          visible: true,
        },
        {
          text: 'Sub Object',
          align: 'center',
          sortable: true,
          value: 'SubObjectCode',
          visible: true,
        },
        {
          text: 'Unit Code',
          align: 'center',
          sortable: true,
          value: 'UnitCode',
          visible: true,
        },
        {
          text: 'Appropriation Unit',
          align: 'center',
          sortable: true,
          value: 'AppropriationUnit',
          visible: true,
        },
        {
          text: 'Activity Code',
          align: 'center',
          sortable: true,
          value: 'ActivityCode',
          visible: true,
        },
        {
          text: 'Line Amount',
          align: 'center',
          sortable: true,
          value: 'LineAmount',
          visible: true,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedById',
          visible: false,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedBy',
          visible: false,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: false,
        },
        {
          text: 'Updated By',
          align: 'center',
          sortable: true,
          value: 'UpdatedBy',
          visible: false,
        },
        {
          text: 'Updated By',
          align: 'center',
          sortable: true,
          value: 'UpdatedById',
          visible: false,
        },
        {
          text: 'Updated Date',
          align: 'center',
          sortable: true,
          value: 'UpdatedDate',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredItemHeaders() {
      return this.itemHeaders.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('wolfsRun', [
      'loadWolfsRuns',
      'updateWolfsRun',
      'downloadWolfsFile',
      'downloadWolfsTextFile',
      'markRunAsDisbursed',
    ]),
    ...call('vwApplicationsAvailableForPayment', [
      'loadVwApplicationsAvailableForPayments',
    ]),
    searchFilter(value, search, item) {
      return (
        //this will run with 'undefined' as the value for the columns with buttons, but not data
        value == undefined &&
        search != null &&
        item.itemJson.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    },
    async cancelRun(item) {
      let selectedRun = new WolfsRun(item)
      selectedRun.IsCanceled = true
      await this.updateWolfsRun(selectedRun.root())
      this.loadWolfsRuns()
      this.loadVwApplicationsAvailableForPayments()
    },
    async disburseRun(item) {
      await this.markRunAsDisbursed(item.Id)
      this.loadWolfsRuns()
    },
    downloadXml(item) {
      this.downloadWolfsFile(item.Id)
    },
    downloadText(item) {
      this.downloadWolfsTextFile(item.Id)
    },
    exportGrid() {},
  },
}
</script>
<style></style>
